<template>
  <div>
    <v-card class="mb-3">
      <v-col
        cols="12"
        :sm="12"
        :md="12"
      >
        <WBSearchQueryFilter
          ref="searchQueryFilterRef"
          :remote-value="''"
          :label="'Поиск объектов по названию или адресу'"
          @onSearchQueryFilterChange="onSearchQueryFilterChange"
          @onSearchQueryFilterCleared="onSearchQueryFilterCleared"
        />
      </v-col>
      <v-card-actions>
        <v-spacer />
        <v-btn
          text
          color="warning"
          :disabled="!searchEnabled"
          @click="clearFilters"
        >
          очистить
        </v-btn>
        <v-btn
          rounded
          color="success"
          outlined
          :disabled="!searchEnabled"
          @click="applyFilters"
        >
          Применить
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-data-table
      :items="modelList"
      :headers="headers"
      :loading="isLoading"
      :options.sync="options"
      :server-items-length="paginator"
      :footer-props="footerProps"
      class="elevation-0"
      fixed-header
      loading-text="Идет загрузка"
      no-results-text="Список пуст"
      no-data-text="Список пуст"
      dense
    >
      <template v-slot:item.supportNumber="{ item }">
        <v-btn
          link
          text
          color="primary"
          @click.stop="onRowClick(item)"
        >
          <v-icon
            small
            class="mr-1"
          >
            mdi-ok
          </v-icon>
          <span style="text-decoration: underline">{{ item.supportNumber }}</span>
        </v-btn>
      </template>
      <template
        v-slot:item.availableOffersNumber="{ item }"
      >
        <v-btn
          text
          :color="item.availableOffersNumber < 5 ? 'warning' : undefined "
        >
          {{ item.availableOffersNumber }}
        </v-btn>
      </template>
      <template
        v-slot:item.amount="{ item }"
      >
        <v-btn
          text
          :color="item.amount < 5 ? 'warning' : undefined "
        >
          {{ item.amount }}
        </v-btn>
      </template>
      <template v-slot:item.createdAt="{ item }">
        {{ item.createdAt | moment("DD/MM/YY HH:mm") }}
      </template>
      <template v-slot:item.options="{ item }">
        <v-btn
          text
          @click="$emit('rowClicked', item)"
        >
          <v-icon>mdi-eye</v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import ListMixin from '@/mixins/list-mixin.vue'
import WBSearchQueryFilter from '@/components/addressSearch/search-query-filter'

export default {
  name: 'OfficeListTable',
  components: { WBSearchQueryFilter },
  mixins: [ListMixin],
  props: {
    paginator: {
      type: Number,
      required: true
    },
    modelList: {
      type: Array,
      required: true
    },
    isLoading: {
      type: Boolean,
      default: true
    },
  },
  data () {
    return {
      officeListFilterSet: {
        searchQuery: ''
      },
      options: {
      },
      headers: [
        {
          text: 'Номер объекта',
          align: 'left',
          sortable: false,
          value: 'supportNumber',
        },
        {
          text: 'Название объекта',
          align: 'left',
          sortable: false,
          value: 'title',
        },
        {
          text: 'Тип объекта',
          align: 'left',
          sortable: false,
          value: 'type.title',
        },
        {
          text: 'Адрес объекта',
          align: 'left',
          sortable: false,
          value: 'address',
        },
        {
          text: 'Баланс офферов',
          align: 'left',
          sortable: false,
          value: 'availableOffersNumber',
        },
        {
          text: 'Баланс ДС',
          align: 'left',
          sortable: false,
          value: 'amount',
        },
        {
          text: 'Создан',
          align: 'left',
          sortable: false,
          value: 'createdAt',
        },
        // {
        //   text: 'Менеджеров',
        //   align: 'left',
        //   sortable: false,
        //   value: 'managersCount',
        // },
      ],
    }
  },
  computed: {
    searchEnabled () {
      const { searchQuery } = this.officeListFilterSet
      return !this.isLoading && !!searchQuery && searchQuery.trim() !== ''
    },
  },
  watch: {
    options: {
      handler () {
        this.$emit('onPaginationOptionsChange', this.options)
      },
      deep: true,
    },
  },
  created () {
    this.options = {
      page: 1,
      itemsPerPage: this.footerProps.itemsPerPageOptions[0],
    }
  },

  methods: {
    onRowClick (data) {
      this.$emit('onRowClick', data)
    },
    async clearFilters () {
      if (this.isLoading) {
        return
      }
      const searchQueryFilterRef = this.$refs.searchQueryFilterRef
      if (searchQueryFilterRef) {
        await searchQueryFilterRef.onClearSearch()
      }
    },
    applyFilters () {
      if (this.isLoading) {
        return
      }
      const filters = {}
      if (this.officeListFilterSet.searchQuery.length > 3) {
        filters.searchQuery = this.officeListFilterSet.searchQuery
      }

      const params = { ...this.options }
      if (Object.keys(filters).length) {
        params.filters = filters
      }

      this.AppLogger.info(params, 'params')

      this.$emit('onPaginationOptionsChange', params)
    },
    onSearchQueryFilterChange (data) {
      this.AppLogger.info(data, 'onSearchQueryFilterChange')
      this.officeListFilterSet.searchQuery = data
    },
    onSearchQueryFilterCleared () {
      this.options = {
        page: 1,
        itemsPerPage: this.footerProps.itemsPerPageOptions[0],
      }
      this.AppLogger.info('onSearchQueryFilterCleared')
      this.officeListFilterSet.searchQuery = ''
      this.applyFilters()
    },
  }
}
</script>

<style lang="sass" scoped>
  $countColor: #A7A7A7
  $fontWeightBold: 800
</style>
