<template>
  <div style="flex: 1">
    <AppInput
      v-model="querySearch"
      middle
      style="margin-bottom: 2px; width: 100%"
      :placeholder="'Поиск объектов по названию или адресу'"
      :keyInput="'user-list-search'"
      @input="$emit('onSearch', querySearch)"
    />
  </div>
</template>

<script>
import AppInput from '@/components/common/simple/AppInput.vue'

export default {
  name: 'AddressSearch',
  components: { AppInput },

  data () {
    return {
      querySearch: ''
    }
  }
}
</script>

<style lang="sass" scoped>

</style>
