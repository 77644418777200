<template>
  <AppMainContainer
    center-class="offer"
    :show-modal="!!currentModal"
    @isScroll="(val) => {isScroll = val}"
    @closeModal="closeModal"
  >
    <template #subheader>
      <PageHeader :isAdmin="isOrganizationRoot" />
    </template>

    <template #center>
      <OfficeFilterBarHeader
        :filters="filters"
        :tabValue="tabValueSet"
        :isEdit="isEdit"
        :manageable="(officeList && officeList.length > 0) && false"
        currentLayout="table"
        @edit="isEdit = !isEdit"
        @showCreateModal="onShowCreateModal"
        @changeStatus="onChangeStatusEvent"
      />
      <AddressSearch
        v-if="loadList === 10000"
        @onSearch="onSearch($event)"
      />
      <OfficeListTable
        :model-list="officeList"
        :is-loading="loadList"
        :paginator="paginatorTotal"
        @onRowClick="goToOfficeItem"
        @onPaginationOptionsChange="onPaginationOptionsChange"
        @requestOfficeList="onPaginationOptionsChange"
      />
      <AppTable
        v-if="isEdit === 250000"
        :keys="keys"
        :array="officeList"
        :event="fetchOffersList"
        :isShowLoad="loadList"
        @click="goToOfficeItem"
      >
        <template #managersCount="{item: office}">
          Не указано
        </template>
        <template #amount="{item: office}">
          <AppTextMoney
            :money="office.amount"
            class="price__text_bold"
          />
        </template>
      </AppTable>
    </template>

    <template #modal>
      <AppModalMiddle
        v-if="currentModal === modalName.createOffice"
        style="padding: 30px"
      >
        <ModalHeader @close="closeModal">
          {{ selectedOffice ? 'Редактирование объекта ' : 'Создание объекта ' }}
        </ModalHeader>

        <div class="user-form__input-group">
          <div style="position:relative;">
            <v-autocomplete
              v-model="selectedAddress"
              style="width: 100%"
              :items="addressList"
              dense
              rounded
              color="#8BD118"
              outlined
              :disabled="addressSearchLoader"
              :search-input.sync="addressSearch"
              clearable
              :no-data-text="addressSearchLoader || isNewSearch ? undefined : `Адрес не найден`"
              hide-selected
              :hide-no-data="addressSearchLoader || isNewSearch"
              return-object
              :loading="false"
              item-value="id"
              item-text="address"
              :placeholder="'Адрес (обязательное поле)'"
              :multiple="false"
              persistent-hint
              clear-icon="fa-close"
              :append-outer-icon="!addressSearchLoader ? 'fa-search' : undefined"
              @click:clear="onAddressCleared"
              @change="onAddressChange"
              @click:append-outer="onSearchAddress"
              @input="onInput"
            >
              <template v-slot:selection="data">
                {{ decodeAddress(data.item.fullAddress) }}
              </template>
              <template v-slot:item="data">
                <template v-if="typeof data.item !== 'object'">
                  <v-list-item-content v-text="data.item" />
                </template>
                <template v-else>
                  <v-list-item-content>
                    <v-list-item-title v-html="decodeAddress(data.item.fullAddress)" />
                  </v-list-item-content>
                </template>
              </template>
            </v-autocomplete>
            <div
              v-if="addressSearchLoader"
              style="position: absolute; bottom: 36px; left: 0; right: 0; text-align: center"
            >
              <div style="display: flex; flex-direction: row; align-items: center; justify-content: center">
                <AppLoader style="max-width: 90%" />
              </div>
            </div>
          </div>
          <div
            v-for="field in officeFields"
            :key="field.key"
            class="input-group__input"
          >
            <component
              :is="field.type"
              v-model="field.value"
              :placeholder="field.placeholder"
              :validationList="field.validator"
              :keyInput="field.key"
              :serverValidation="serverValidation"
              hideClose
              :values="selectedList[field.values]"
              :visualValue="field.value ? field.value[field.visualValue] : ''"
              :disabled="field.disabled"
              :onChange="field.onChange"
              :onValidEvent="field.onValidEvent"
              showValidationMessage
              size="medium"
            >
              <template #default="{value: office}">
                {{ office.title }}
              </template>
              <template #labelText>
                {{ field.labelText }}
              </template>
            </component>
          </div>
        </div>

        <div
          style="margin: 10px"
          class="user-form__button-group"
        >
          <AppLoader
            v-if="currentLoader === loaderData.OFFICE_FORM"
            style="margin: 10px"
          />
          <AppButton
            v-if="currentLoader !== loaderData.OFFICE_FORM"
            :disabled="!isFormValid"
            style="margin-left: auto"
            @click.native="submitOfficeForm"
          >
            Создать объект
          </AppButton>
        </div>
      </AppModalMiddle>

      <AppModalSmall
        v-if="currentModal === modalName.successCreateOffer"
        style="padding: 70px 106px"
      >
        <h1 class="modal__header">
          Объект добавлен
        </h1>
        <p class="modal__description">
          Наш менеджер свяжется с вами для уточнения данных и активации объекта
        </p>
        <div class="modal__button">
          <AppButton
            @click.native="currentModal = ''"
          >
            Ок
          </AppButton>
        </div>
      </AppModalSmall>

      <AppModalSmall
        v-if="currentModal === modalName.updateCreateOffer"
        style="padding: 70px 106px"
      >
        <h1 class="modal__header">
          Объект обновлен
        </h1>
        <p class="modal__description">
          Наш менеджер свяжется с вами для уточнения данных и активации объекта
        </p>
        <div class="modal__button">
          <AppButton
            @click.native="currentModal = ''"
          >
            Ок
          </AppButton>
        </div>
      </AppModalSmall>
    </template>
  </AppMainContainer>
</template>

<script>
import AppMainContainer from '@/components/common/layout/AppMainContainer'
import OfferListHeader from '@/components/offer/OfferListHeader'
import AppTable from '@/components/common/AppTable'
import officeApi from '@/mixins/office/officeApi'
import OfficeFilterBarHeader from '@/components/office/OfficeFilterBarHeader'
import AppModalMiddle from '@/components/common/modal/AppModalMiddle'
import ModalHeader from '@/components/common/modal/ModalHeader'
import AppInput from '@/components/common/simple/AppInput'
import { notEmptyField } from '@/utils/validation'
import AppTextArea from '@/components/common/simple/AppTextArea'
import AppButton from '@/components/common/simple/AppButton'
import AppModalSmall from '@/components/common/modal/AppModalSmall'
import { saveToLocalStorage } from '@/mixins/local_storage'
// import AppCombobox from '@/components/common/simple/AppCombobox'
import AppRadio from '@/components/common/simple/AppRadio'
import AppLoader from '@/components/AppLoader'
import AppTextMoney from '@/components/common/AppTextMoney'
import routeList from '@/router/labels'
import userUtil from '@/utils/user-util'
import AppSelect from '@/components/common/simple/AppSelect'
import md5 from 'js-md5'
import PageHeader from '@/components/subheaders/PageHeader.vue'
import AddressSearch from '@/components/addressSearch/AddressSearch.vue'
import OfficeListTable from '@/components/office/officeListTable'
const modalName = Object.freeze({
  createOffice: 'createOffice',
  successCreateOffer: 'successCreateOffer',
  updateCreateOffer: 'updateCreateOffer',
})

export default {
  name: 'PageOfficeList',
  components: {
    OfficeListTable,
    AddressSearch,
    PageHeader,
    AppTextMoney,
    AppSelect,
    AppRadio,
    AppLoader,
    AppModalSmall,
    AppButton,
    ModalHeader,
    AppModalMiddle, OfficeFilterBarHeader,
    AppTable, OfferListHeader,
    AppMainContainer, AppInput,
  },
  mixins: [officeApi],
  data () {
    const statusGroupList = {
      STATUS_ACTIVE: 'active',
      STATUS_INACTIVE: 'inactive',
    }
    return {
      modelList: [],
      paginatorTotal: 0,
      tabValueSet: {
        active: 0,
        inactive: 0,
      },
      pageSize: null,
      currentFilters: null,
      querySearch: null,
      addressSearch: null,
      isNewSearch: true,
      addressSearchLoader: false,
      addressList: [],

      isEdit: false,
      address: '',
      loaderData: {
        OFFICE_FORM: 'OFFICE_FORM',
        OFFICE_LIST: 'OFFICE_LIST',
      },
      statusGroupList,
      currentLoader: null,
      isScroll: false,

      serverValidation: null,

      filters: { statusGroup: statusGroupList.STATUS_ACTIVE },

      currentModal: '',
      modalName,

      keys: [
        { label: 'Название объекта', value: 'title', config: { canSort: false } },
        { label: 'Тип объекта', value: 'type.title', config: { canSort: false } },
        { label: 'Адрес объекта', value: 'address', config: { canSort: true } },
        { label: 'Офферов', value: 'availableOffersNumber', config: { canSort: true } },
        { label: 'Менеджеров', value: 'managersCount', config: { canSort: true } },
        { label: 'Баланс объекта', value: 'amount', config: { canSort: true } },
        // { label: 'Регион', value: 'region.title', config: { canSort: true } },
      ],

      selectedOffice: null,
      selectedAddress: null,
      officeFields: [],

      radioButton: [
        { label: 'Нормальный', value: 'normal', name: 'temperature' },
        { label: 'Холодный', value: 'cold', name: 'temperature' }
      ]
    }
  },
  computed: {
    officeExists () {
      return this.$store.state.office.userOffice && this.$store.state.office.userOffice.id
    },
    isActive () {
      return (this.selectedOffice && this.selectedOffice.status === 'active')
    },
    loadList () {
      return this.currentLoader === this.loaderData.OFFICE_LIST
    },
    officeList () {
      // const list = this.modelList
      // if (!this.querySearch || this.querySearch.length < 2) {
      //   return list
      // }
      // return list.filter((item) => {
      //   return this.filterSearch(item, this.querySearch, '')
      // })

      return this.modelList
    },
    regionList () {
      return []
    },
    officeTypeList () {
      return this.$store.state.office.typeList || []
    },
    getUser () {
      return this.$store.state.users.info
    },
    isOrganizationRoot () {
      return userUtil.isRoot(this.getUser) || userUtil.isAdmin(this.getUser)
    },
    selectedList () {
      return {
        regionList: [],
        typeList: this.officeTypeList,
      }
    },
    isFormValid () {
      const validationMap = this.officeFields.filter((item) => item.validator).map((item) => item.validator)
      const allValidateFields = validationMap
      const isValidFields = allValidateFields.filter((value) => value.every((el) => el.valid))

      return allValidateFields.length === isValidFields.length
    }
  },

  watch: {
    // addressSearch (val) {
    //   val && val !== this.selectedAddress && this.querySelections(val)
    // },
    'filters': {
      deep: true,
      handler () {
        if (this.pageSize && !this.loadList) {
          this.fetchOffersList(1, this.pageSize)
        }
      }
    },
  },
  destroyed () {
    this.clearModelList()
  },
  created () {
    this.currentLoader = this.loaderData.OFFICE_LIST
    this.eventsBus.$on(this.eventNames.ALIAS_ON_OFFICE_CONFIRMED_NOTIFICATION,
      () => {
        this.fetchOffersList(1, this.pageSize)
      }
    )
    this.initOffice()
    this.$store.dispatch('vacancy/fetchRegionList')
    this.$store.dispatch('office/fetchTypeList')
  },
  methods: {
    async getAddress ($event) {
      this.address = ''
      this.eventsBus.$emit('OnGetAddress', this.address)
      const rep = await this.$store.dispatch('office/getAddress', { address: $event })
      if (rep && rep.fullAddress && rep.fullAddress.length > 1) {
        this.address = rep.fullAddress
      } else {
        this.address = null
      }
      this.eventsBus.$emit('OnGetAddress', this.address, false)
    },
    onSearch (data) {
      this.querySearch = data
    },
    filterSearch (item, queryText, itemText) {
      const searchText = queryText.toLowerCase()
      const title = item.title.toLowerCase()
      const address = item.address.toLowerCase()

      return title.indexOf(searchText) > -1 ||
        address.indexOf(searchText) > -1
    },
    onShowCreateModal () {
      this.selectedOffice = null
      this.initOffice()
      this.currentModal = this.modalName.createOffice
    },
    async onChangeStatusEvent ($event) {
      await this.clearModelList()
      this.filters.statusGroup = $event
    },
    initOffice (isNew = false) {
      this.address = null
      this.officeFields = [
        {
          type: AppInput, placeholder: 'Название объекта (обязательное поле) ', value: '', key: 'title',
          disabled: false,
          onValueUpdated: this.check,
          onValidEvent: 'updatePhone',
          validator: [
            {
              text: 'Заполните название объекта',
              valid: false,
              validator: (value) => notEmptyField(value)
            }
          ]
        },
        {
          type: AppSelect, placeholder: 'Тип', value: '', key: 'type', values: 'typeList',
          visualValue: 'title',
          validator: [
            // { text: 'Заполните роль', valid: false, validator: (value) => notEmptyField(value) }
          ]
        },
        {
          type: AppTextArea, placeholder: 'Схема прохода на объект', value: '', key: 'route',
          disabled: false,
          validator: []
        },
        // {
        //   type: AppCombobox,
        //   placeholder: 'Корпоративный траспорт',
        //   value: false,
        //   key: 'hasTransportation',
        //   disabled: false,
        //   labelText: 'Корпоративный траспорт',
        //   validator: []
        // },
      ]
    },

    // onRouteReload () {
    //   this.$store.dispatch('vacancy/fetchRegionList')
    //   this.fetchOffersList()
    // },

    async fetchOffersList (page = 1, size = 5) {
      const activeFilters = this.currentFilters

      try {
        const pagination = { 'page[number]': page, 'page[size]': size }
        const status = this.filters.statusGroup === 'active' ? 'active' : 'draft'

        let params = { ...pagination, ...this.filters, status }

        if (activeFilters) {
          params = {
            ...params,
            ...activeFilters,
          }
        }

        this.pageSize = size
        this.currentLoader = this.loaderData.OFFICE_LIST

        const response = await this.$store.dispatch(
          'office/getOfficeList',
          { params })

        // this.$store.commit(`vacancy/${VACANCY_STORE_TYPES.SET_TABS_COUNT_DATA}`, {
        //   data: {
        //     activeCount: response.headers['x-active-count'] || 0,
        //     inactiveCount: response.headers['x-inactive-count'] || 0,
        //     draftCount: response.headers['x-draft-count'] || 0,
        //     totalCount: response.headers['x-total-count'] || 0,
        //   }
        // })

        this.modelList = response.data.map((item) => ({
          ...item,
        }))

        if (response.headers['x-total-count']) {
          this.paginatorTotal = parseInt(response.headers['x-total-count'], 10)
          this.tabValueSet.active = response.headers['x-active-count']
          this.tabValueSet.inactive = response.headers['x-inactive-count']
        } else {
          this.paginatorTotal = this.footerProps.itemsPerPageOptions[1]
        }

        this.currentLoader = null
      } catch (error) {
        this.currentLoader = null
        console.log(error)
      }
    },
    async onPaginationOptionsChange (options) {
      await this.clearModelList()
      this.currentFilters = options.filters
      await this.fetchOffersList(options.page, options.itemsPerPage)
    },

    closeModal () {
      this.initOffice()
      this.currentModal = ''
    },

    async goToOfficeItem (office) {
      if (!this.isEdit) {
        await this.clearModelList()
        this.currentLoader = this.loaderData.OFFICE_LIST
      }
      saveToLocalStorage('office', office.id, true)
      this.$store.commit('office/setUserOffice', office)

      this.$store.dispatch('office/fetchOfficeById', { officeId: office.id })
        .then((data) => {
          if (this.isEdit) {
            this.officeFields.forEach((field) => {
              field.value = data[field.key]
              if (field.key === 'address') {
                field.disabled = (data.status !== 'draft')
              }
            })
            this.selectedOffice = data

            this.currentModal = this.modalName.createOffice
          } else {
            this.$router.push({
              name: routeList.OFFICE_OFFER_LIST_NAME,
              params: { id: this.$store.state.office.userOffice.id },
              query: { statusGroup: 'active' }
            })
          }
        })
    },

    submitOfficeForm () {
      this.isEdit && this.selectedOffice ? this.updateOffice() : this.createOffice()
    },

    createOffice () {
      if (!this.selectedAddress.fullAddress) {
        return
      }
      const decodedAddress = this.decodeAddress(this.selectedAddress.fullAddress)
      if (!decodedAddress) {
        return
      }
      const data = this.officeFields.map((item) => ({ key: item.key, value: item.value }))
        .reduce((result, val) => ({
          ...result, [val.key]: val.value
        }), {})
      data.organization = this.$store.state.users.info.organization
      data.address = decodedAddress
      data.uri = this.selectedAddress.uri

      this.currentLoader = this.loaderData.OFFICE_FORM
      this.$store.dispatch('office/fetchOfficeCreate', data)
        .then(() => {
          this.closeModal()
          this.currentModal = this.modalName.successCreateOffer
          this.officeFields.forEach((item) => {
            item.value = ''
          })

          this.filters = {
            statusGroup: this.statusGroupList.STATUS_INACTIVE
          }
          this.clearAddressSearch()
        }).catch((error) => {
          // TODO отображать ошибку
          if (error.data) this.serverValidation = error.data.violations
          this.currentLoader = null
        })
    },

    clearAddressSearch () {
      this.querySearch = null
      this.addressSearch = null
      this.isNewSearch = true
      this.addressSearchLoader = false
      this.addressList = []
    },

    updateOffice () {
      const data = this.officeFields.map((item) => ({ key: item.key, value: item.value }))
        .reduce((result, val) => ({
          ...result, [val.key]: val.value
        }), {})
      data.organization = this.$store.state.users.info.organization
      data.id = this.selectedOffice.id

      this.currentLoader = this.loaderData.OFFICE_FORM

      this.$store.dispatch('office/fetchOfficeUpdate', data)
        .then(() => {
          this.closeModal()
          this.currentModal = this.modalName.updateCreateOffer
          this.officeFields.forEach((item) => {
            item.value = ''
            item.disabled = false
          })

          if (this.selectedOffice.status === this.statusGroupList.STATUS_ACTIVE) {
            this.filters = {
              statusGroup: this.statusGroupList.STATUS_ACTIVE
            }
          } else {
            this.filters = {
              statusGroup: this.statusGroupList.STATUS_INACTIVE
            }
          }
          this.selectedOffice = null
          this.currentLoader = null
          this.clearAddressSearch()
        }).catch(() => {
        // TODO отображать ошибку
          this.currentLoader = null
        })
    },
    async querySelections (address) {
      if (!address || address.trim() === '') {
        return
      }
      // Simulated ajax query
      setTimeout(async () => {
        this.addressSearchLoader = true
        this.address = ''
        // this.eventsBus.$emit('OnGetAddress', this.address)
        const rep = await this.$store.dispatch('office/getAddress', { address })

        try {
          if (rep && rep.length) {
            this.addressList = rep.filter(
              (point) => !!point.fullAddress && point.fullAddress.trim() !== ''
            ).map((item) => ({
              ...item,
              address: `${address}---${item.fullAddress}`,
              id: md5(item.fullAddress)
            }))
          } else {
            // this.address = null
            // this.addressList = []
          }
          this.addressSearchLoader = false
          this.isNewSearch = false
        } catch (error) {
          this.addressSearchLoader = false
          this.isNewSearch = false
          console.log(error)
        }

        // this.eventsBus.$emit('OnGetAddress', this.address, false)
      }, 500)
    },
    decodeAddress (address) {
      if (!address) {
        return ''
      }
      const parts = address.split('---')
      if (parts.length > 1) {
        return parts[1]
      }
      return address
    },

    async onAddressChange ($event) {
    },
    async onAddressCleared () {
    },
    async onInput () {
      this.isNewSearch = true
    },
    async clearModelList () {
      await this.$store.commit('office/setOfficeItems', [])
      this.modelList = []
    },
    async onSearchAddress ($event) {
      await this.querySelections(this.addressSearch)
      // this.address = ''
      // this.eventsBus.$emit('OnGetAddress', this.address)
      // const rep = await this.$store.dispatch('office/getAddress', { address: $event })
      // if (rep && rep.address && rep.address.length > 1) {
      //   this.address = rep.address
      // } else {
      //   this.address = null
      // }
      // this.eventsBus.$emit('OnGetAddress', this.address, false)
    }
  }
}
</script>

<style lang="sass" scoped>

  .user-form__input-group
    display: flex
    flex-direction: column
    margin-top: 15px
    padding-bottom: 15px
    border-bottom: $default_border_bottom

    .input-group__input
      margin-top: 15px

    .user-form__button-group
      display: flex
      margin-top: 30px

    .modal__input-temperature__wrapper
      flex-direction: column
      align-items: flex-start

    .modal__input-group
      display: flex
      align-items: flex-start
      margin-top: 15px
      border-bottom: $default_border_bottom
      padding-bottom: 20px

      &:last-of-type
        border: 0
        padding-bottom: 0

    .radio-group
      display: flex
      margin-top: 20px
</style>
