<template>
  <div class="office__filter-top">
    <div
      class="filter-top__line"
    >
      <div
        class="filter-top__element filter-button"
        @click="showModalOfficeCreate"
      >
        <IconPlus class="add__icon" />
        <span class="button-text">Добавить объект</span>
      </div>
      <div
        v-if="manageable"
        class="filter_layout"
      >
        <div
          v-if="!isEdit"
          class="filter-top__element"
          @click="$emit('edit')"
        >
          <IconPanEdit />
          <span class="filter-top__element__element-text office__text-management">
            {{ $t('offerList.header.button.control') }}
          </span>
        </div>

        <div
          v-else
          class="filter-top__element"
        >
          <span
            class="filter-top__element__element-text office__text-management"
            @click="$emit('edit')"
          >
            Ок
          </span>
          <span
            class="filter-top__element__element-text office__text-management filter-top__element__element-text_warm"
            @click="$emit('openModal')"
          >
            <!--            Удалить-->
          </span>
        </div>
      </div>

      <div class="filter_layout filter_layout__icon-group">
        <!--        <component-->
        <!--          :is="layout.label"-->
        <!--          v-for="layout in layouts"-->
        <!--          :key="layout.value"-->
        <!--          class="filter-top__element__icon"-->
        <!--          :class="layout.class"-->
        <!--          :isActive="currentLayout === layout.value"-->
        <!--          @click="$emit('changeLayout', layout.value)"-->
        <!--        />-->
      </div>
    </div>

    <div class="filter-top__left-group">
      <span
        v-for="item in statusGroups"
        :key="item.value"
        class="filter-top__element__text"
        :class="{'filter-top__element__text_active': filters.statusGroup === item.value}"
        @click="changeStatus(item.value)"
      >{{ `${item.label} (${tabValue[item.value]})` }}</span>
    </div>
  </div>
</template>

<script>
import IconPlus from '@/components/common/icons/IconPlus'
import IconPanEdit from '@/components/common/icons/IconPanEdit'
import IconGrid from '@/components/common/icons/IconGrid'
import IconTable from '@/components/common/icons/IconTable'


export default {
  name: 'OfficeFilterBarHeader',
  components: {
    IconPlus,
    IconPanEdit,
    // IconGrid, IconTable
  },
  props: {
    tabValue: {
      type: Object,
      required: true,
    },
    filters: {
      type: Object,
      required: true,
    },
    isEdit: {
      type: Boolean,
      required: true,
    },
    manageable: {
      type: Boolean,
      required: false,
    },
    currentLayout: {
      type: String,
      required: true,
    },
  },
  data () {
    return {
      statusGroups: [
        { label: 'Активные', value: 'active', count: 'activeOffers' },
        { label: 'Ожидают активации', value: 'inactive', count: '0' },
      ],

      layouts: [
        { label: IconGrid, value: 'grid', class: 'office__icon__grid' },
        { label: IconTable, value: 'table', class: 'office__icon__table' }
      ]
    }
  },
  computed: {
    userOffice () {
      return this.$store.state.office.userOffice
    }
  },
  methods: {
    changeStatus (type) {
      this.$emit('changeStatus', type)
    },
    showModalOfficeCreate () {
      this.$emit('showCreateModal')
    },
  }

}
</script>

<style lang="sass" scoped>
  .office__filter-top
    width: 100%
    display: flex
    flex-direction: column
    align-items: center

  .filter-top, .filter-bottom


    &__left-group, &__right-group
      margin-right: 30px

  .filter-top__line
    display: flex
    width: 100%
    align-items: center

  .filter_layout
    margin-left: auto

    &__icon-group
      border-left: $default_border_bottom
      margin-left: 15px

  .filter-top__left-group
    margin-top: 20px
    margin-bottom: 20px
    margin-right: auto
</style>
